.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.sub-title-color {
  color: #F67C2A;
}
/* other button colors */
.green-button{
  background-color: #87bb33;
}

.green-button:hover{
  background-color: #669122;
}

.cyan-button{
  background-color: #22c0cb;
}

.cyan-button:hover{
  background-color: #12a2ac;
}

.bg-lab-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}